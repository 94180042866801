import React from 'react';
import ErrorLogo from '@public/adicon_alert_filled.svg';
import { PageTemplate } from '@components/page-template';
var Custom404 = function () {
    return (<PageTemplate data-testid="404-page" imageSrc={ErrorLogo} header={<>
          404
          <br />
          ページが見つかりませんでした。
        </>} description="URLを確認して下さい。"/>);
};
export default Custom404;
